import resolve from '@utils/resolve';
import getConfig from 'next/config';
import { cfetch, sfetch } from '@utils/auth';
const { publicRuntimeConfig } = getConfig();

/**
 * Vérifie qu'une room existe
 *
 */
const roomDispo = async (token: string, roomId: string) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/events/roomDispo', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ roomId: roomId })
        })
    );
};

/**
 * Bannir une adresse email
 *
 */
const banEmail = async (token: string, roomId: string, email: string) => {
    return await resolve(
        sfetch(token, publicRuntimeConfig.API_BASE_URL + '/events/ban', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ roomId: roomId, email: email })
        })
    );
};

/**
 * Renvoie les informations d'un event
 *
 */
const getEventInfo = async (token: string, id: string) => {
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL +
                '/events/infoByRoomId?roomId=' +
                id,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

/**
 * Inscription à un event
 *
 */
const inscription = async (token: string, roomId: string, values: any) => {
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL + '/participants/inscription',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ roomId: roomId, data: values })
            }
        )
    );
};

/**
 * Upload Files
 *
 */
const uploadFilesFromFreeEventRegister = async (
    token: string,
    formDataFiles: any
) => {
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL +
                '/events/uploadFilesFromFreeEventRegister',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    files: formDataFiles
                })
            }
        )
    );
};

/**
 *  Mettre fin a un event
 *
 */
const closeEvent = async (token: string, eventId: string) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/events/end', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ eventId: eventId })
        })
    );
};

// Renvoie un mail au participant validé
const reSendInscriptionMail = async (
    token: string,
    emailSignup: string,
    roomId: string
) => {
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL +
                '/participants/reSendInscriptionMail',
            {
                method: 'post',
                body: JSON.stringify({ email: emailSignup, roomId: roomId }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

export const APIEvents = {
    roomDispo: roomDispo,
    getEventInfo: getEventInfo,
    inscription: inscription,
    closeEvent: closeEvent,
    reSendInscriptionMail: reSendInscriptionMail,
    banEmail: banEmail,
    uploadFilesFromFreeEventRegister: uploadFilesFromFreeEventRegister
};
