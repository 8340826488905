export const ACTION_TYPES = {
    Errors: {
        addError: 'Errors_addError',
        addErrorPage: 'Errors_addErrorPage',
        reset: 'Errors_reset',
        resetPage: 'Errors_resetPage'
    },
    Loading: {
        start: 'Loading_start',
        stop: 'Loading_stop',
        setText: 'Loading_setText'
    },
    Chat: {
        banEmail: 'Chat_banEmail',
        kickUser: 'Chat_kickUser',
        muteUser: 'Chat_muteUser',
        unMuteUser: 'Chat_unMuteUser',
        hideMsg: 'Chat_hideMsg',
        unHideMsg: 'Chat_unHideMsg',
        deleteMsg: 'Chat_deleteMsg'
    },
    Call: {
        setCallObject: 'Call_setCallObject',
        setMeetingState: 'Call_setMeetingState',
        setParticipants: 'Participant_setParticipants',
        setRoomId: 'Call_setRoomId',
        setNetworkQuality: 'Call_setNetworkQuality',
        pinParticipant: 'Call_pinParticipant',
        setScreenShare: 'Call_setScreenShare',
        setReceptionRoom: 'Call_setReceptionRoom',
        setActiveSpeaker: 'Call_setActiveSpeaker',
        setisConnectingLocal: 'Call_setisConnectingLocal',
        toggleDefaultMuted: 'Call_toggleDefaultMuted',
        setDefaultMuted: 'Call_setDefaultMuted',
        toggleDefaultNoCam: 'Call_toggleDefaultNoCam',
        setDefaultNoCam: 'Call_setDefaultNoCam',
        resetState: 'Call_resetState',
        setWaitingParticipantsRoom: 'Call_setWaitingParticipantsRoom',
        addWaitingParticipantsRoom: 'Call_addWaitingParticipantsRoom',
        removeWaitingParticipantsRoom: 'Call_removeWaitingParticipantsRoom',
        tchatMute: 'Call_tchatMute',
        setMutedParticipant: 'Call_setMutedParticipant',
        getFiche: 'Call_getFiche',
        sendFiche: 'Call_sendFiche'
    },
    UI: {
        toggleShowListUsers: 'UI_toggleShowListUsers',
        toggleShowWaitingList: 'UI_toggleShowWaitingList',
        toggleShowEventInfos: 'UI_toggleShowEventInfos',
        toggleShowRoom: 'UI_toggleShowRoom',
        toggleAddUser: 'UI_toggleAddUser',
        toggleChat: 'UI_toggleChat',
        setNoMic: 'UI_setNoMic',
        setNoCam: 'UI_setNoCam',
        resetState: 'UI_resetState',
        selectedParticipants: 'UI_selectedParticipants',
        selectedWaitingParticipants: 'UI_selectedWaitingParticipants',
        selectedIntervenants: 'UI_selectedIntervenants',
        selectedVisiteurs: 'UI_selectedVisiteurs',
        selectedWaitingDispatch: 'UI_selectedWaitingDispatch',
        removeSelectedWaitingDispatch: 'UI_removeSelectedWaitingDispatch',
        setWaitingRoom: 'UI_setWaitingRoom',
        setUnreadedMsgCount: 'UI_setUnreadedMsgCount',
        incrementUnreadedMsgCount: 'UI_incrementUnreadedMsgCount',
        openDrawerCandidat: 'UI_openDrawerCandidat',
        closeDrawerCandidat: 'UI_closeDrawerCandidat',
        setTmpRoomOwner: 'UI_setTmpRoomOwner',
        setDrawerCandidatData: 'UI_setDrawerCandidatData',
        setUsedShortToken: 'UI_setUsedShortToken'
    },
    Config: {
        setConfigDevicesStep: 'Config_setConfigDevicesStep',
        setDevices: 'Config_setDevices',
        setNeedReception: 'Config_setNeedReception'
    },
    Auth: {
        setClientToken: 'Auth_setClientToken',
        setUser: 'Auth_setUser',
        setMeetingToken: 'Auth_setMeetingToken',
        setFiche: 'Auth_setFiche'
    },
    Data: {
        setEvent: 'Data_setEvent',
        setParticipantsConnected: 'Data_setParticipantsConnected',
        removeParticipantsConnected: 'Data_removeParticipantsConnected',
        addParticipantsConnected: 'Data_addParticipantsConnected',
        removeParticipantReception: 'Data_removeParticipantReception',
        addParticipantReception: 'Data_addParticipantReception',
        setParticipantsReception: 'Data_setParticipantsReception',
        initiateParticipantReception: 'Data_initiateParticipantReception',
        removeInitiateParticipantReception:
            'Data_removeInitiateParticipantReception',
        setMercureTokenEvent: 'Data_setMercureTokenEvent',
        setLastEventId: 'Data_setLastEventId',
        setLastEventIdReception: 'Data_setLastEventIdReception',
        setMessage: 'Data_setMessage',
        resetMessages: 'Data_resetMessages',
        deleteMessage: 'Data_deleteMessage',
        hideMessage: 'Data_hideMessage',
        unHideMessage: 'Data_unHideMessage',
        setNotes: 'Data_setNotes',
        setNbParticipantsRoom: 'Data_setNbParticipantsRoom',
        setNbParticipantsWaitingRoom: 'Data_setNbParticipantsWaitingRoom'
    },
    Page: {
        Join: {
            setWaitingShortToken: 'PageJoin_waitingShortToken',
            setWaitingMeetingToken: 'PageJoin_waitingMeetingToken',
            setStatusSignup: 'PageJoin_setStatusSignup',
            setEmailSignup: 'PageJoin_setEmailSignup',
            mailSended: 'PageJoin_mailSended'
        }
    }
};
