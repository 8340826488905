import resolve from '../../utils/resolve';
import getConfig from 'next/config';
import { cfetch } from '@utils/auth';
import { InscriptionForm } from '@components/Home/HomeSection/FormSection';
const { publicRuntimeConfig } = getConfig();

// Récupérer un user
const getByShortToken = async (
    ctoken: string,
    shortToken: string,
    roomId: string
) => {
    return await resolve(
        cfetch(ctoken, publicRuntimeConfig.API_BASE_URL + '/short_token', {
            method: 'post',
            body: JSON.stringify({ shortToken: shortToken, roomId: roomId }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

// Mettre à jour son statut de présence
const updatePresence = async (ctoken: string, userId: string) => {
    return await resolve(
        cfetch(
            ctoken,
            publicRuntimeConfig.API_BASE_URL + '/participants/updatePresence',
            {
                method: 'post',
                body: JSON.stringify({ userId: userId }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

const signUp = async (ctoken: string, user: InscriptionForm) => {
    return await resolve(
        cfetch(ctoken, publicRuntimeConfig.API_BASE_URL + '/client/signup', {
            method: 'post',
            body: JSON.stringify(user),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

// Un email est-il disponible?
const emailDispo = async (token: string, email: string) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/emailDispo', {
            method: 'post',
            body: JSON.stringify({
                email: email
            }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

export const APIUsers = {
    getByShortToken: getByShortToken,
    updatePresence: updatePresence,
    signUp: signUp,
    emailDispo: emailDispo
};
