import { ACTION_TYPES } from '@actions/actions_types';

const addError = (message: string) => ({
    payload: { message: message },
    type: ACTION_TYPES.Errors.addError
});

const addErrorPage = (message: string) => ({
    payload: { message: message },
    type: ACTION_TYPES.Errors.addErrorPage
});

const handleErrors = (res: any, dispatch: any) => {
    if (res.networkError) return dispatch(addError('erreur network à traiter'));
    else if (res.data.error) return dispatch(addErrorPage(res.data.message));
    else return false;
};

const reset = () => ({
    type: ACTION_TYPES.Errors.reset
});

const resetPage = () => ({
    type: ACTION_TYPES.Errors.resetPage
});

export const ErrorsActions = {
    addError: addError,
    handleErrors: handleErrors,
    reset: reset,
    resetPage: resetPage
};
