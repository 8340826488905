import { ACTION_TYPES } from '@actions/actions_types';
import { NextPageContext } from 'next';
import jwt from 'jwt-simple';
import { APIAuth } from '@api/authApi';
import { ErrorsActions } from '@actions/errors_actions';
import { setClientTokenStorage } from '@utils/auth';
import { APIDaily } from '@api/dailyApi';
import getConfig from 'next/config';
import { setLocalStorage } from '@utils/localStorage';
const { publicRuntimeConfig } = getConfig();
import { v4 as uuidv4 } from 'uuid';
import { APIEvents } from '@api/eventApi';
import { getNameByPattern } from '@logic/func';
import { IUser } from '@interfaces/logic/auth';
import { APIUsers } from '@api/usersApi';

// Mettre à jour les tokens en redux
const setClientToken = (ctoken: string) => ({
    payload: { token: ctoken },
    type: ACTION_TYPES.Auth.setClientToken
});

// Mettre à jour le user
const setUser = (user: IUser | null) => ({
    payload: { user: user },
    type: ACTION_TYPES.Auth.setUser
});

// Mettre à jour le user
const setMeetingToken = (token: string) => ({
    payload: { meetingToken: token },
    type: ACTION_TYPES.Auth.setMeetingToken
});

// Rejoindre un évènement
const joinEvent = (
    eventRoomId: string,
    userName: string,
    intervenant: boolean,
    shortToken?: string,
    id?: string,
    ctoken?: string,
    didAnswerSurvey?: boolean,
    permissions?: { [key: string]: boolean }[]
) => (dispatch: any) => {
    const userId = id ? `${id}` : uuidv4();
    // On récupère un meeting token
    APIDaily.createMeetingToken(userName, '', intervenant, userId).then(r => {
        if (r && r.data) {
            let token = '';
            if (r.data) {
                token = r.data.token;
                // On créé le token JWT pour le lier à l'event et on le met en localstorage
                const payload = {
                    eventRoomId: eventRoomId,
                    meetingToken: token,
                    userId: userId
                };
                const JWTtoken = jwt.encode(
                    payload,
                    publicRuntimeConfig.SECRET_JWT
                );
                setLocalStorage('meetingTokenEvent', { token: JWTtoken });

                // On met à jour son statut de présence dans les participants
                if (ctoken) APIUsers.updatePresence(ctoken, userId);

                dispatch(
                    setUser({
                        userId: userId,
                        userName: userName,
                        intervenant: intervenant,
                        shortToken: shortToken ? shortToken : '',
                        didAnswerSurvey: didAnswerSurvey
                            ? didAnswerSurvey
                            : false,
                        permissions: permissions ?? []
                    })
                );
                dispatch(setMeetingToken(token));
            }
        }
    });
};

// Récupérer les nouveaux tokens à partir d'un refresh_token
const getClientToken = (ctx: NextPageContext) => (dispatch: any) =>
    APIAuth.getClientToken().then(r => {
        const errors = ErrorsActions.handleErrors(r, dispatch);
        if (!errors) {
            dispatch(setClientToken(r.data.access_token));
            setClientTokenStorage(r.data.access_token, ctx);
        }
    });

const setFiche = (formData: any, fields: any, files: any) => ({
    payload: { formData: formData, fields: fields, files: files },
    type: ACTION_TYPES.Auth.setFiche
});

const inscriptionFree = (formData: any, infosEvent: any, token: string) => (
    dispatch: any
) => {
    const formDataFiles: any = [];
    Object.entries(formData).forEach(([key]) => {
        if (key.includes('_file'))
            formDataFiles.push({
                key: key,
                data: formData[key]
            });
    });
    const userName = getNameByPattern(
        infosEvent.paternName,
        formData.prenom,
        formData.nom,
        formData.username
    );

    if (userName != undefined) {
        if (formDataFiles.length > 0) {
            APIEvents.uploadFilesFromFreeEventRegister(
                token,
                formDataFiles
            ).then(res => {
                dispatch(setFiche(formData, infosEvent.fields, res.data.files));
                dispatch(joinEvent(infosEvent.roomId, userName, false));
            });
        } else {
            dispatch(setFiche(formData, infosEvent.fields, []));
            dispatch(joinEvent(infosEvent.roomId, userName, false));
        }
    }
};

export const AuthActions = {
    setClientToken: setClientToken,
    getClientToken: getClientToken,
    setUser: setUser,
    joinEvent: joinEvent,
    setMeetingToken: setMeetingToken,
    inscriptionFree: inscriptionFree,
    setFiche: setFiche
};
