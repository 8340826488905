import { ACTION_TYPES } from '@actions/actions_types';
import { ErrorsActions } from '@actions/errors_actions';
import { APIEvents } from '@api/eventApi';

const setWaitingShortToken = (waitingShortToken: boolean) => ({
    payload: { waitingShortToken: waitingShortToken },
    type: ACTION_TYPES.Page.Join.setWaitingShortToken
});

const setWaitingMeetingToken = (waitingMeetingToken: boolean) => ({
    payload: { waitingMeetingToken: waitingMeetingToken },
    type: ACTION_TYPES.Page.Join.setWaitingMeetingToken
});

const setStatusSignup = (status: string) => ({
    payload: { statusSignup: status },
    type: ACTION_TYPES.Page.Join.setStatusSignup
});

const setEmailSignup = (email: string) => ({
    payload: { emailSignup: email },
    type: ACTION_TYPES.Page.Join.setEmailSignup
});

// Notifier que le recruteur a bien été supprimé
const mailSended = () => ({
    type: ACTION_TYPES.Page.Join.mailSended
});

// Renvoie un mail au participant validé
const reSendInscriptionMail = (
    ctoken: string,
    emailSignup: string,
    roomId: string
) => (dispatch: any) =>
    APIEvents.reSendInscriptionMail(ctoken, emailSignup, roomId).then(r => {
        const errors = ErrorsActions.handleErrors(r, dispatch);
        if (!errors) {
            dispatch(mailSended());
        } else return errors;
    });

export const PageJoinActions = {
    setWaitingShortToken: setWaitingShortToken,
    setWaitingMeetingToken: setWaitingMeetingToken,
    setStatusSignup: setStatusSignup,
    setEmailSignup: setEmailSignup,
    reSendInscriptionMail: reSendInscriptionMail
};
