export const setLocalStorage = (key: string, value: object) => {
    if (typeof window != 'undefined')
        localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
    if (typeof window != 'undefined') {
        const objectString = localStorage.getItem(key);
        if (objectString) return JSON.parse(objectString);
    }
};

export const rmLocalStorage = (key: string) => {
    if (typeof window != 'undefined') {
        localStorage.removeItem(key);
    }
};

export const clearLocalStorage = () => {
    if (typeof window != 'undefined') {
        localStorage.clear();
    }
};
