/**
 * Fonction servant à construire le nom d'utilisateur selon le pattern prédéfini de l'évènement
 *
 * @return {string} Nom de l'utilisateur selon le pattern de l'évènement
 * @param pattern
 * @param firstname
 * @param lastname
 * @param username
 */

export const getNameByPattern = (
    pattern: EnumPaternName,
    firstname?: string,
    lastname?: string,
    username?: string
): string | undefined => {
    return pattern == 'USERNAME'
        ? username
        : pattern == 'LASTNAMEFIRSTNAME'
        ? lastname + ' ' + firstname
        : pattern == 'FIRSTNAMELASTNAME'
        ? firstname + ' ' + lastname
        : pattern == 'FIRSTNAMEONLY'
        ? firstname
        : pattern == 'LASTNAMEONLY'
        ? lastname
        : undefined;
};
