import resolve from '@utils/resolve';
import { sfetch } from '@utils/auth';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

// Une room existe-t'elle
const getRoomStatus = async (roomId: string) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/rooms/' + roomId
        )
    );
};

// Un meeting token existe-t-il
const getMeetingTokenStatus = async (token: string) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/meeting-tokens/' + token
        )
    );
};

// Créer une room
const createRoom = async (roomId: string) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/rooms/',
            {
                method: 'post',
                body: JSON.stringify({ name: roomId, privacy: 'public' }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

// Créer un meeting token
const createMeetingToken = async (
    userName: string,
    roomName: string,
    isOwner: boolean,
    userId: string
) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/meeting-tokens',
            {
                method: 'post',
                body: JSON.stringify({
                    properties: {
                        user_name: userName,
                        room_name: roomName,
                        is_owner: isOwner,
                        user_id: userId
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

//  Active ou désactive le micro
const toggleDefaultMuted = async (roomId: string, defaultMuted: boolean) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/rooms/' + roomId,
            {
                method: 'post',
                body: JSON.stringify({
                    properties: {
                        start_audio_off: defaultMuted
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

//  Active ou désactive la caméra
const toggleDefaultNoCam = async (roomId: string, defaultNoCam: boolean) => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/rooms/' + roomId,
            {
                method: 'post',
                body: JSON.stringify({
                    properties: {
                        start_video_off: defaultNoCam
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

// Une room existe-t'elle
const getPresence = async () => {
    return await resolve(
        sfetch(
            publicRuntimeConfig.DAILY_API_KEY,
            'https://api.daily.co/v1/presence'
        )
    );
};

export const APIDaily = {
    getRoomStatus: getRoomStatus,
    createRoom: createRoom,
    createMeetingToken: createMeetingToken,
    getMeetingTokenStatus: getMeetingTokenStatus,
    toggleDefaultMuted: toggleDefaultMuted,
    toggleDefaultNoCam: toggleDefaultNoCam,
    getPresence
};
