import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { PageJoinActions } from '@actions/pages/join_actions';
import { useMSTranslation } from '@utils/useMSTranslation';

export const SIGNUP_STATUS_IDLE = 'SIGNUP_STATUS_IDLE';
export const SIGNUP_STATUS_VALID = 'SIGNUP_STATUS_VALID';
export const SIGNUP_STATUS_FULL = 'SIGNUP_STATUS_FULL';
export const SIGNUP_STATUS_ENDED = 'SIGNUP_STATUS_ENDED';
export const SIGNUP_STATUS_ALREADYREGISTERED =
    'SIGNUP_STATUS_ALREADYREGISTERED';
export const SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE =
    'SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE';
export const SIGNUP_STATUS_ALREADYREGISTEREDREFUSED =
    'SIGNUP_STATUS_ALREADYREGISTEREDREFUSED';
export const SIGNUP_STATUS_NOT_STARTED = 'SIGNUP_STATUS_NOT_STARTED';
export const SignupRegistered = (props: { email: string }) => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-warning'>
                {__('Vous êtes déjà enregistré', 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'Dès que l’hôte aura validé votre participation, vous recevrez un lien d’accès par email sur {{email}}',
                    'join',
                    { email: props.email }
                )}
                .
            </p>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'En cliquant sur ce lien vous accéderez directement à la visioconférence.',
                    'join'
                )}
            </p>
        </div>
    );
};

export const SignupRegisteredValidate = (props: { emailSignup: string }) => {
    const { __ } = useMSTranslation(['join']);

    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const roomId = useSelector((state: IStore) => state.Data.event?.roomId);

    const mailSended = useSelector(
        (state: IStore) => state.PageJoin.mailSended
    );
    const { emailSignup } = props;
    const dispatch = useDispatch();

    return (
        <div>
            <div className='text-2xl text-warning'>
                {__("Vous avez été validé à l'évènement", 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    "Vous avez reçu un lien d'accès par email vous permettant de vous connecter directement à l'évènement.",
                    'join'
                )}
            </p>
            {mailSended ? (
                <p className='text-lg leading-normal'>
                    {__('Email envoyé', 'join')} !
                </p>
            ) : (
                <p className='text-lg leading-normal'>
                    <a
                        href='#'
                        onClick={(e: any) => {
                            e.preventDefault();
                            if (ctoken && roomId)
                                return dispatch(
                                    PageJoinActions.reSendInscriptionMail(
                                        ctoken,
                                        emailSignup,
                                        roomId
                                    )
                                );
                        }}
                    >
                        {__('Renvoyer le lien d’accès', 'join')}
                    </a>
                </p>
            )}
        </div>
    );
};

export const SignupRegisteredRefused = (props: { roomName: string }) => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-warning'>
                {__("Vous avez été refusé à l'évènement", 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    "Vous n'avez malheureusement pas été sélectionné pour l'évènement {{roomName}}. Retentez votre chance la prochaine fois !",
                    'join',
                    { roomName: props.roomName }
                )}
            </p>
        </div>
    );
};

export const SignupValidate = (props: { email: string }) => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-success'>
                <FontAwesomeIcon icon={['far', 'check']} className='mr-1' />
                {__('Inscription enregistrée', 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'Dès que l’hôte aura validé votre participation, vous recevrez un lien d’accès par email sur {{email}}',
                    'join',
                    { email: props.email }
                )}
                .
            </p>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'En cliquant sur ce lien vous accéderez directement à la visioconférence.',
                    'join'
                )}
            </p>
        </div>
    );
};

export const SignupFull = () => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-warning'>
                <FontAwesomeIcon
                    icon={['far', 'hand-paper']}
                    className='mr-1'
                />
                {__("L'évènement est plein", 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'Vous ne pouvez pas rejoindre l’évènement en raison d’un nombre de places limité',
                    'join'
                )}
                .
            </p>
        </div>
    );
};

export const SignupEnded = () => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-warning'>
                <FontAwesomeIcon
                    icon={['far', 'hand-paper']}
                    className='mr-1'
                />
                {__("L'évènement est terminé", 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    'Vous ne pouvez pas rejoindre l’évènement, il est maintenant terminé',
                    'join'
                )}
                .
            </p>
        </div>
    );
};

export const SignupNotStartedYet = () => {
    const { __ } = useMSTranslation(['join']);

    return (
        <div>
            <div className='text-2xl text-warning'>
                <FontAwesomeIcon
                    icon={['far', 'hand-paper']}
                    className='mr-1'
                />
                {__("L'évènement n'a pas encore commencé", 'join')}
            </div>
            <p className='text-lg text-main opacity-75 leading-normal'>
                {__(
                    "Vous ne pouvez pas rejoindre l’évènement, il n'a pas encore commencé",
                    'join'
                )}
                .
            </p>
        </div>
    );
};
